import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import queryString from 'query-string'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faTruck, faCreditCard, faListAlt, faPrint, faPhone, faMagic, faThumbsUp, faPlus, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import produce from "immer"
import { isMobile } from 'react-device-detect';

import Steps from "../../components/layout/Steps"
import Card from "../../components/layout/Card"
import ExpansionPanel from "../../components/layout/ExpansionPanel"
import AccountPage from "../../components/layout/AccountPage"
import HoverButton from "../../components/buttons/HoverButton"
import CartItems from "../../components/checkout/CartItems"
import Layout2Columns from "../../components/layout/Layout2Columns";
import PaymentForm from "../../components/checkout/PaymentForm"
import TextField from "../../components/fields/TextField"
import MajorError from "../../components/MajorError"
import Coupon from "../../components/Coupon"
import GoogleImage from "../../components/GoogleImage"
import Button from "../../components/buttons/Button"
import SuccessBox from "../../components/boxes/SuccessBox"

import { postData } from "../../services/server"
import { colors, phones, devices } from "../../settings"
import { formatPrice } from "../../utilities/utils"
import NumberStepperField from "../../components/fields/NumberStepperField";

const StatusBar = styled.div`
	.MuiPaper-root {
		background-color: transparent !important;
	}
	.MuiStepIcon-root.MuiStepIcon-completed {
		color: ${colors.green} !important;
	}
`
const OrderTotal = styled.div`
	margin-top: 15px;

	> div {
		display: flex;
		align-items: center;
		margin-bottom: 3px;
		font-size: 18px;		

		label {
			min-width: 110px;
		}
		span {
			display: inline-block;
			min-width: 100px;
			text-align: right;
		}

		&.total {
			margin-top: 8px;
			padding-top: 8px;
			border-top: 1px solid #eee;

			label {
				font-weight: bold;
			}
			span {
				color: ${colors.green};
			}
		}
		&.balance, &.discount {
			span {
				color: red;
			}
		}
	}
`
const Address = styled.div`
  > div {
	  margin-bottom: 5px;
	  font-size: 15px;
  }
`
const Shipments = styled.div`
  h2 {
	  font-size: 15px;
	  margin: 20px 0px 10px 0px;
  }

  > div {
	  display: flex;
	  align-items: center;
	  margin-bottom: 5px;

	  label {
		  border: 1px solid #ccc;
		  padding: 5px 10px;
		  font-size: 15px;
		  border-radius: 5px;
		  margin-right: 5px;
	  }
  }
`
const PaymentContainer = styled.div`
  border:1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: white;
  padding: 10px;

  h3 {
	  margin: 15px 0px 15px 10px;
	  font-weight: normal;
  }
  div.alternative {
	  margin: 15px;
  }

`
const Payments = styled.div`
  h2 {
	  font-size: 15px;
	  margin: 10px 0px;
  }

  > div {
	  display: flex;
	  align-items: center;
	  margin-bottom: 5px;

	  span {
		flex-basis: 33%;
	  }
	  label {
		flex-basis: 33%;
	  }
	  strong {
		flex-basis: 33%;
	  }
  }
`
const Approval = styled.div`
  > div {
	  display: flex;

	  @media ${devices.mobile} {
        display: block;
	  }
		  
	  > div {
		  padding: 10px;
		  margin-bottom: 10px;

		  @media ${devices.mobile} {
			padding: 0px;
		  }
	  }
  }
  form {
	  > div {
		  display: flex;

		  @media ${devices.mobile} {
			display: block;
		  }

		  input {
			  flex: 1;
		  }
		  button {
			  flex: 0;
			  margin-left: 3px;
			  min-width: 205px;
			  border: 2px solid ${colors.green};
			  white-space: nowrap;
		  }
	  }
  }
`
const Approvals = styled.table`
  width: 100%;
  thead {
	@media ${devices.mobile} {
		display: none;
	}
  }
  label {
	  font-weight: bold;
	  display: none;

	  @media ${devices.mobile} {
		display: block;
	  }
  }
  tr {
	@media ${devices.mobile} {
		display: block;
		border-bottom: 1px solid #ccc;
	}
  }
  tr:last-child {
	  border-bottom: 0px;
  }
  th {
	  background-color: #eee;
	  padding: 5px;
  }
  td {
	  padding: 5px;

	  @media ${devices.mobile} {
		display: block;
		padding: 5px 0px;
	  }
  }
`

const OrderStatusBar = (props) => {
  const { order } = props;
  let activeStep = "payment";

  if (order.balance && order.balance > 0)
  	activeStep = "payment";
  else if (["shipped","completed"].indexOf(order.job_status_name) != -1)
	activeStep = "shipped";
  else if (["building","qa","shipping"].indexOf(order.job_status_name) != -1)
	activeStep = "manufacturing";
  else if (["pending", "hold", "fraudulent", "paymnetvoid"].indexOf(order.status_name) != -1)
	activeStep = "payment";
  else 
    activeStep = "breakdown";

  return (
	  <StatusBar>
		{order.status_title == "Hold" ? 
		  	<div className="hold">
				Order is currently on <strong>Hold</strong>.  Please give us a 
				call at <FontAwesomeSvgIcon icon={faPhone} /> <a href={"tel:" + phones.production}>{phones.production}</a>.
			</div>
		:
			<div style={{margin: "20px"}}>
				<Steps 
					activeStep={activeStep} 
					steps={[
						{name:"payment", label:"Payment"},
						{name:"breakdown", label:"Breakdown"},
						{name:"manufacturing", label:"Manufacturing"},
						{name:"shipped", label:"Shipped"},
					]}
					orientation={isMobile?"vertical":"horizontal"}
				/>
			</div>
		}
	  </StatusBar>
  )
}

const OrderPage = () => {
  const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
  const orderUid = params.uid;

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState("");
  const [approved, setApproved] = useState("");
  const [formData, setFormData] = useState({
    approve: "",
    alterations: "",
  });  

  useEffect(
    () => {
        setLoading(true);

        postData("/api/orders/data", { uid: orderUid },
          function(result) {
			setOrder(result);
			setFormData(produce(draft => {
				draft.billing_zipcode = result.billing_zipcode;
				draft.billing_name = result.billing_name;
				draft.payment = result.balance;
			}));
          },
          function(error) {
              setError(error);
          },
          function() {
              setLoading(false);
          }
        );
    }, 
    [orderUid]
  );

  function onPayment(response) {
	setBusy("payment");

	postData("/api/orders/payment", {
			...response.opaqueData,
			order_id: order.id,
			amount: formData.payment || order.balance
		}, 
		(response) => {
			setOrder(response)
		}, 
		(error, status, response) => {
			setError(
				response && response.data && response.data.error ? response.data.error : "An error occurred processing your payment"
			  );
		  },
		() => {
		  setBusy("")
		}
	);  
  }
  function onPaymentError(response) {
	console.log("error respone", response)
	const error = response.messages.message.map(err => err.text);

	setError(error);

	postData("/api/cart/problem", {
		cart_id: order.id,
		action: "payment",
		problem: error
	});  
  }

  function handleFormChange(event) {
	if (event.persist) event.persist();
	
    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;
    }));
  }

  function onApprove() {
	setBusy("approve")

	postData("/api/orders/approve", {
			approval_id: order.approval.id
		}, 
		(response) => {
			setOrder(response)
			setApproved("approve")
		}, 
		(error, status) => {
			setError(error);
		},
		() => {
		  setBusy("")
		}
	); 
  }
  function onAlterations() {
	setBusy("alterations")

	postData("/api/orders/alterations", {
			approval_id: order.approval.id,
			comments: formData.alterations
		}, 
		(response) => {
			setOrder(response)
			setApproved("alterations")
		}, 
		(error, status) => {
			setError(error);
		},
		() => {
		  setBusy("")
		}
	); 
  }

  return (
	<AccountPage 
		page="orders" 
		title={order ? "Order #"+ order.id : "Order Details"}
		breadcrumb={[
			{url: "/", title: "Home"},
			{url: "/account/orders/", title: "Orders"},
			{url: null, title: "Order"}
		]}
		loading={loading} 
	>
        <>
          {order && <OrderStatusBar order={order} />}
		  <Card style={{marginBottom: "15px"}}>
				{order && 
					<Layout2Columns align="top" left={70} right={30}>
						<div>
							<OrderTotal>
								<div>
									<label>Order Date:</label>
									<span>{order.order_datetime_formatted}</span>
								</div>
								<div>
									<label>Order Total:</label>
									<span>{formatPrice(order.cost)}</span>
								</div>
								{(order.discount && order.discount > 0) && 
									<div className="discount">
										<label>Discount:</label>
										<span>-{formatPrice(order.discount)}</span>
									</div>
								}
								{(order.coupon_discount && order.coupon_discount > 0) && 
									<div className="discount">
										<label>Purchase Code:</label>
										<span>-{formatPrice(order.coupon_discount)}</span>
										{order.coupon_code && 
											<Coupon code={order.coupon_code} />
										}
									</div>
								}
								<div>
									<label>Tax:</label>
									<span>{formatPrice(order.tax)}</span>
								</div>
								<div>
									<label>Shipping:</label>
									{order.shipping_surcharge ? 
									<span>{formatPrice(order.shipping_surcharge)}</span>
									:
									<span>FREE</span>
									}
								</div>
								<div className="total">
									<label>Grand Total:</label>
									<span>{formatPrice(order.total)}</span>
								</div>
								{(order.balance && order.balance > 0) && 
									<div className="balance">
										<label>Balance:</label>
										<span>
											{formatPrice(order.balance)}
										</span>
									</div>
								}
							</OrderTotal>
						</div>
						<div  style={{textAlign:"right"}}>
							<Link to={"/account/invoice/?uid=" + order.uid}>
								<HoverButton
									label="Print Invoice"
									icon={faPrint}
									color="blue"
								/>
							</Link>
						</div>
					</Layout2Columns>
				}	
		  </Card>

		  {(order && approved.length > 0) &&
			<SuccessBox>
				{approved == "approve" ? 
					"We have received your approval and will begin production on your sign."
				:
					"We have received your alteration comments and will contact you soon to discuss."
				}
            </SuccessBox>
		  }

		  {(order && order.approval) && 
			<ExpansionPanel 
				expanded={true}
				icon={faMagic}
				title="Approval Needed"
				color="red"
			>
				{order ?
					<Approval>
						<div>
							<GoogleImage root src={order.approval.preview_url} required lazy alt="Approval" style={{maxWidth: "300px"}} />
							<div>
								{order.approval.notes}
							</div>
						</div>
						<form>
							<p>
								Type APPROVED below to approve this for production:
							</p>
							<div>
								<TextField
									type="text"
									name="approve"
									value={formData.approve}
									onChange={handleFormChange}
									wide
								>
									<Button
										color={!formData.approve || formData.approve.toLowerCase() != "approved" ? "lightGrey":"green"}
										label="Approve Design"
										icon={faThumbsUp}
										disabled={!formData.approve || formData.approve.toLowerCase() != "approved"}
										busy={busy == "approve"}
										onClick={onApprove}
										style={{marginTop: "5px", marginBottom: "5px"}}
									/>
								</TextField>
							</div>
							<div>
								<TextField
									type="text"
									name="alterations"
									multiline
									rows={5}
									value={formData.alterations}
									onChange={handleFormChange}
									wide
								>
									<Button
										color={!formData.alterations || formData.alterations.length == 0 ? "lightGrey":"green"}
										label="Make Alterations"
										icon={faPlus}
										disabled={!formData.alterations || formData.alterations.length == 0}
										busy={busy == "alterations"}
										onClick={onAlterations}
										style={{marginTop: "5px", marginBottom: "5px"}}
									/>
								</TextField>
							</div>
						</form>
					</Approval>
					:
					<div></div>
				}	
			</ExpansionPanel>
		  }
		  {(order && order.approvals && order.approvals.length > 0) && 
			<ExpansionPanel
				icon={faThumbsUp}
				title="Approvals"				
			>
				<Approvals>
					<thead>
						<tr>
							<th>Date</th>
							<th>Preview</th>
							<th>Approved</th>
							<th>Comments</th>
						</tr>
					</thead>
					<tbody>
					{order && order.approvals.map((approval,index) => (
						<tr key={index}>
							<td>
								<label>Date Requested:</label>
								{approval.requested_date_formatted}
							</td>
							<td>
								<GoogleImage root src={approval.preview_url} required lazy alt="Approval Image" style={{maxWidth: "150px"}} />
							</td>
							<td>
								<label>Approved:</label>
								<FontAwesomeSvgIcon icon={approval.approved_ind == 1 || approval.approved_ind == 99 ? faThumbsUp:faThumbsDown} style={{color: approval.approved_ind == 1 || approval.approved_ind == 99 ? "green":"red"}} />
								{" "}
								{approval.approved_ind == 1 || approval.approved_ind == 99 ? "Yes":"No"}
							</td>
							<td>
								<label>Comments:</label>
								<small>{approval.comments}</small>
							</td>
						</tr>
					))}
					</tbody>
				</Approvals>
			</ExpansionPanel>
		  }

		  {(order && order.balance > 0) && 
		  	<Card style={{marginBottom: "15px"}}>
				<h3>
					Make an addition payment to cover the outstanding balance.
				</h3>

				<TextField
					type="text"
					name="billing_name"
					label="Billing Name"
					value={formData.billing_name}
					onChange={handleFormChange}
					wide
				/>
				<TextField
					type="text"
					name="billing_zipcode"
					label="Billing Zip Code"
					value={formData.billing_zipcode}
					onChange={handleFormChange}
				/>
				<TextField
					type="number"
					name="payment"
					label="Payment Amount"
					value={formData.payment}
					onChange={handleFormChange}
				/>

				<br />

				<PaymentForm
					buttonLabel="Submit Payment"
					onError={onPaymentError}
					onSuccess={onPayment}
					busy={busy == "payment"}
					zip={formData.billing_zipcode}
					fullName={formData.billing_name}
				/>
				<br />

				<div className="alternative">
					Give us a call at <FontAwesomeSvgIcon icon={faPhone} /> <a href={"tel:" + phones.sales}>{phones.sales}</a> if you would 
					like to arrange a different type of payment.
				</div>
			</Card>
		  }

		  <ExpansionPanel 
		  	expanded={true}
			icon={faListAlt}
			title="Items Ordered"
		>
			{order && 
				<CartItems 
					items={order.items}
					headers
					status
					quantity
				/>
			}	
		  </ExpansionPanel>

		  <ExpansionPanel
		  	icon={faCreditCard}
			title="Billing / Payments"
		  >
				{order && 
					<Layout2Columns align="top">
						<div>
							<Address>
								{order.billing_name && <div>{order.billing_name}</div>}
								{order.billing_company && <div>{order.billing_company}</div>}
								<div>{order.billing_address1}</div>
								{order.billing_address2 && <div>{order.billing_address2}</div>}
								<div>{order.billing_city}</div>
								<div>{order.billing_state}</div>
								<div>{order.billing_zipcode}</div>
							</Address>
						</div>
        				<div>
							{order.payments.length > 0 && 
								<Payments>
									<h2>Payments</h2>
									{order.payments.map((payment,index) => (
										<div key={index}>
											<span>{payment.payment_date_formatted}</span>
											<label>
												{payment.type}
												{payment.cc_last4 && <>{' '}({payment.cc_last4})</>}
											</label>
											<strong style={{textAlign:"right"}}>
												{formatPrice(payment.amount)}
											</strong>
										</div>
									))}
								</Payments>
							}
						</div>
					</Layout2Columns>
				}
		  </ExpansionPanel>

		  <ExpansionPanel
		  	icon={faTruck}
			title="Shipping"
		  >
			{order && 
				<Layout2Columns align="top">
					<div>
						<Address>
							{order.ship_name && <div>c/o {order.ship_name}</div>}
							<div>{order.ship_address1}</div>
							{order.ship_address2 && <div>{order.ship_address2}</div>}
							<div>{order.ship_city}</div>
							<div>{order.ship_state}</div>
							<div>{order.ship_zipcode}</div>
						</Address>
					</div>
					<div>
						{order.status_name != "shipped" && 
							<>
								{order.boxes.length > 0 && 
									<Shipments>
										<h2>Shipments</h2>
										{order.boxes.map((box,index) => (
											<div key={box.id}>
												<label>{index}</label>
												<span>
													{box.trackingno ? box.trackingno : "Tracking # Coming Soon"}
												</span>
											</div>
										))}
									</Shipments>
								}
							</>
						}
					</div>
				</Layout2Columns>
			}
		  </ExpansionPanel>
        </>
		{(error && error.length>0) && 
			<MajorError 
				error={error} 
				log={error}
				onClose={() => setError("")}
			/>
        }
    </AccountPage>
  )
}

export default OrderPage