import React from "react"
import { isMobile } from "react-device-detect"
import styled from "styled-components"

import { devices, colors } from "../../settings"

const Steps = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: ${colors.blue};

    > div {
        &.active {
            color: ${colors.orange};

            div.number {
                background-color: ${colors.orange};
            }
        }

        div.number {
            background-color: ${colors.blue};
            color: white;
            padding: 5px;
            border-radius: 50%;
            text-align: center;
            width: 30px;
            height: 30px;
            font-size: 16px;
            margin: 0px auto 15px auto;
        }
    }
`

export default (props) => {
  return (
    <Steps orientation={props.orientation}>
    { props.steps.map((step, index) => (
        <div key={index} className={props.activeStep == step.name ? "active":""}>
            <div className="number">{index+1}</div>
            <div>{step.label}</div>
        </div>
    ))}        
    </Steps>
  )
}